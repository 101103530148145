import {
    Logo
} from "../assets/images";

import {
    heroIMG
} from "../assets/images";


export const navLinks = [
    { href: "#home", label: "Home"},
	{ href: "#about-us", label: "About Us" },
	{ href: "#faqs", label: "FAQs" },
	// { href: "#contact-us", label: "Contact Us" },
]